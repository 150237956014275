import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  hostname: string;
  protocol: string;
  port: string;
  aws: string;
  ext: string;
  url: string;
  imUrl: string;

  public events: EventEmitter<any>;

  constructor(
    private http: HttpClient,
    private transSvc: TranslateService,
  ) {
    this.events = new EventEmitter();
    this.hostname = window.location.hostname;
    this.protocol = window.location.protocol;
    this.port = window.location.port;
    this.imUrl = 'https://images.rallygo.eu/';
    /*
    if ( this.hostname === 'localhost' ) {
        this.url = 'http://localhost:3000/api';
      } else {
        this.hostname = 'api.dev.rallygo.eu';
        this.protocol = 'https';
        this.url = this.protocol + '://' + this.hostname + '/api';
    }

    console.log('API', this.url); */
  }

  api() {
    console.log('API', environment.api);
    return environment.api;

    return this.url + '/v2';
  }

  webUrl() {
    return `${window.location.origin}`;
  }

  imagesUrl() {
    return this.imUrl;
  }

  translate( text: string, params: any = {} ) {
    return this.transSvc.instant( text, params );
  }

  queryParams() {
    const params = window.location.search.replace('?','');
    const query = {
      team: null,
      event: null,
    };

    const bodyParams = params.split('&');

      for (let ix = 0; ix < bodyParams.length; ix++) {
        const param = bodyParams[ix].split('=');
        query[param[0]] = decodeURIComponent(param[1] || null);
      }

      return query;
  }

  public getUploadImageUrl( body ) {
    return this.http.post( this.api() + '/app/image-upload-url', body);
  }

  public uploadImage( url, body,  headers ) {
    return this.http.put( url, body, {headers: headers});
  }

  public icons() {
    return this.http.get( this.api() + '/app/icons' );
  }

  public currencies() {
    return this.http.get(`/assets/currencies.json`);
  }

  public countries() {
    return this.http.get(`/assets/countries.json`);
  }

  public makePayment(idevent: string, body: any ) {
    return this.http.post( this.api() + `/payments/imoje/fundraise/${idevent}`, body);
  }

  public getPaymentProviders() {
    return this.http.get( this.api() + `/payments/providers`);
  }

  public getFailureUrl() {
    const url = `${window.location.origin}/payment-failure`;
    return url;
  }

  public getSuccessUrl() {
    const url = `${window.location.origin}/payment-success`;
    return url;
  }
}
