import { SuccessComponent } from './payments/success/success.component';
import { FailureComponent } from './payments/failure/failure.component';
import { Routes } from '@angular/router';
import { SimpleComponent } from './layouts/simple/simple.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: SimpleComponent,
    children: [{
        path: '',
        loadChildren:
          () => import('./deposit/deposit.module').then(m => m.DepositModule)
      },
      { path: 'payment-failure', component: FailureComponent },
      { path: 'payment-success', component: SuccessComponent },
    ]
  },
  { path: '**', redirectTo: '/deposit' }
];
