import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Team } from '../../../models/team';
import { Event } from '../../../models/event';
import { Participant } from '../../../models/participant';
import { AppService } from '../../../services/app.service';
import { MatDialog } from '@angular/material';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.css']
})
export class LinkDialogComponent implements OnInit {
  teams: Team[];
  events: Event[];

  eventForm: FormGroup;
  teamForm: FormGroup;
  summaryForm: FormGroup;

  isLinear = true;
  server: string;
  paymentLink: string;

  constructor(
    private app: AppService,
    public dialog: MatDialog,
    private evSvc: EventsService) { }

  ngOnInit(): void {

    this.eventForm = new FormGroup({
      event: new FormControl('', [Validators.required]),
    });

    this.teamForm = new FormGroup({
      team: new FormControl('', [Validators.required]),
    });

    this.summaryForm = new FormGroup({});
    this.onloadEvents();
    this.paymentLink = this.app.webUrl();
  }

  getSelectedTeam(selected) {

    if (!selected || Array.isArray(selected)) {
      return;
    }

    this.teamForm.controls['team'].setValue( selected );

    this.paymentLink = this.app.webUrl() + `/deposit?event=${this.eventForm.value.event}&team=${this.teamForm.value.team}`;
  }

  onloadEvents() {
    const query = {
      status: 'public',
      limit: 0,
      fundraise: true
    };

    this.evSvc.get( query ).subscribe( (events: Event[]) => {
      this.events = events;
    }, err => {
      console.error( err );
    });
  }

  onLoadTeams() {
    const query = {
      limit: 0
    };

    this.evSvc.participants( this.eventForm.value.event, 'approved', query).subscribe( (participants: Participant[]) => {
      let teams: Team[] = [];

      for (let ix = 0; ix < participants.length; ix++) {
        teams.push( participants[ix].team );
      }

      this.teams = teams;
    }, err => {
      console.error( err );
    });
  }

}
