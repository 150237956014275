<div mat-dialog-title>
    <span>{{'DIALOG.LINKGEN.TITLE' | translate}}</span>
</div>
<div mat-dialog-content>

    <mat-vertical-stepper [linear]="isLinear" #depositStepper class="m-t-20">
        <mat-step [stepControl]="eventForm">
            <form [formGroup]="eventForm">
                <ng-template matStepLabel>{{ 'DEPOSIT.STAGES._1_.TITLE' | translate }}</ng-template>
                <mat-form-field>
                    <mat-select formControlName="event" placeholder="{{ 'DEPOSIT.STAGES._1_.PLACEHOLDER' | translate }}" required>
                        <mat-option *ngFor="let ev of events" value="{{ev._id}}">{{ev.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="button-row">
                    <button mat-raised-button color="accent" [disabled]="eventForm.invalid" matStepperNext color="accent" (click)="onLoadTeams()">{{'NEXT'|translate}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="teamForm">
            <form [formGroup]="teamForm">
                <ng-template matStepLabel>{{ 'DEPOSIT.STAGES._2_.TITLE' | translate }}</ng-template>
                <mat-select-autocomplete *ngIf="teams" placeholder="{{ 'DEPOSIT.STAGES._2_.PLACEHOLDER' | translate }}" [options]="teams" [display]="'name'" [value]="'_id'" [multiple]='false' [labelCount]="2" [selectedOptions]="" formControlName="team" ngDefaultControl
                    (selectionChange)="getSelectedTeam($event)">
                </mat-select-autocomplete>
                <mat-spinner *ngIf="!teams"></mat-spinner>
                <div class="button-row" *ngIf="teams">
                    <button mat-raised-button color="primary" matStepperPrevious>{{'BACK'|translate}}</button>
                    <button mat-raised-button color="accent" [disabled]="teamForm.invalid" matStepperNext>{{'NEXT'|translate}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="summaryForm">
            <ng-template matStepLabel>{{ 'DEPOSIT.STAGES._4_.TITLE' | translate }}</ng-template>

            <div class="link">
                <a href="{{paymentLink}}" target="_blank">{{paymentLink}}</a>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</div>