import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.css']
})
export class PaymentDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentForm') payform: ElementRef;

  form: any;
  formParams: any[];
  errors: any;

  constructor(
    private app: AppService,
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = {
      action: '',
      method: '',
      enctype: '',
      body: []
    };
  }

  ngOnInit(): void {
    this.formParams = [];
  }

  ngAfterViewInit() {

    this.app.makePayment(this.data.event, this.data.body).subscribe( async (payment: any) => {
      const bodyParams = payment.action.contentBodyRaw.split('&');

      for (let ix = 0; ix < bodyParams.length; ix++) {
        const param = bodyParams[ix].split('=');
        const input: any = {};

        input.name = param[0];
        input.value = decodeURIComponent(param[1] || '');

        this.formParams.push( input );
      }

      this.form.action = payment.action.url;
      this.form.method = payment.action.method;
      this.form.body = this.formParams;
      this.form.enctype = payment.action.contentType;

      this.payform.nativeElement.setAttribute('action', this.form.action);
      this.payform.nativeElement.setAttribute('method', this.form.method);
      //this.payform.nativeElement.setAttribute('enctype', this.form.enctype);

      // Deply to allow build payment on iMoje Gateway
      await new Promise(r => setTimeout(r, 2000));

      this.payform.nativeElement.submit();

    }, err => {
      console.error( err );
    });
  }
}
