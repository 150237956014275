<button mat-button (click)="onLinkGenerate()">{{ 'MENU.BUTTONS.GENERATE_LINK' | translate }}</button>
<button [matMenuTriggerFor]="lang" mat-icon-button class="m-r-5">
    <mat-icon>language</mat-icon>
</button>
<mat-menu #lang="matMenu">
    <button mat-menu-item (click)="onSetLang('pl')">
        Polski
    </button>
    <button mat-menu-item (click)="onSetLang('en')">
        English
    </button>
</mat-menu>