<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
    <mat-toolbar color="primary" class="topbar telative">

        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <span fxShow="false" fxShow.gt-xs>
                    <img src="assets/rgo-logo-web.png" class="rallygo-logo light-logo" alt="homepage">
                </span>
            </a>

            <span fxFlex></span>
        </div>
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>

    <mat-sidenav-container [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <mat-sidenav-content class="page-wrapper">
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>