import { NgModule } from '@angular/core';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { MaterialModule } from '../material-module';
import { EventSelectorComponent } from './event-selector/event-selector.component';
import { EventSelectorDialogComponent } from './event-selector-dialog/event-selector-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamSummaryComponent } from './team-summary/team-summary.component';
import { EventSummaryComponent } from './event-summary/event-summary.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LinkDialogComponent } from './link-dialog/link-dialog.component';

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    EventSelectorComponent,
    EventSelectorDialogComponent,
    TeamSummaryComponent,
    EventSummaryComponent,
    PaymentDialogComponent,
    LinkDialogComponent,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    EventSelectorComponent,
    EventSelectorDialogComponent,
    TeamSummaryComponent,
    EventSummaryComponent,
   ],
  providers: [  ]
})
export class SharedModule { }
