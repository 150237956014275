<div mat-dialog-title>
    <span>{{'DIALOG.PAYMENT.TITLE' | translate}}</span>
</div>
<div mat-dialog-content>
    <div *ngIf="form">
        <form id="paymentForm" #paymentForm action="" method="" name="paymentForm">
            <input type="hidden" name="{{param.name}}" value="{{param.value}}" *ngFor="let param of formParams" />
        </form>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="!errors">
            <div fxFlex="100" class="text-center">
                <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
                <p>{{'DIALOG.PAYMENT.SENDING_REQUEST' | translate}}</p>
            </div>
        </div>
        <div *ngIf="errors">
            :)
        </div>
    </div>
</div>