import { LinkDialogComponent } from './../../shared/link-dialog/link-dialog.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    public auth: AuthService) {

  }

  onSetLang( lang ) {
    this.translate.setDefaultLang( lang );
    this.translate.use( lang );
  }

  onLinkGenerate() {
    const dialogRef = this.dialog.open(LinkDialogComponent, {
      width: '600px',
      closeOnNavigation: false,
      disableClose: false
    });
  }
}
